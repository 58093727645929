import { graphql } from 'gatsby'
import React from 'react'
import type { HeadProps } from 'gatsby'
import SEO from '~/components/seo'
import Layout from '~/components/layout'
import { Link } from 'gatsby'
import { TagsPageQuery } from '/types/graphql-types'
import { css } from '@emotion/react'
import { PAGE_HEADING_STYLE, TAG_LINK_STYLE } from '~/styles/common'

export const pageQuery = graphql`
  query TagsPage {
    allContentfulArticle {
      group(field: tags___slug) {
        fieldValue
        totalCount
      }
    }
    allContentfulTag(sort: { order: ASC, fields: title }) {
      edges {
        node {
          slug
          title
          article {
            slug
          }
        }
      }
    }
  }
`

interface Props {
  data: TagsPageQuery
}

type TagCountMapType = { [slug: string]: number }

const TITLE = `タグ一覧`

const TagsPage: React.FC<Props> = ({ data }) => {
  const { edges } = data.allContentfulTag
  const { group } = data.allContentfulArticle

  const tagCountMap: TagCountMapType = group.reduce((map, tag) => {
    map[tag.fieldValue!] = tag.totalCount
    return map
  }, {} as TagCountMapType)

  return (
    <Layout>
      <h2 css={style.heading}>{TITLE}</h2>
      <div css={style.wrapper}>
        {edges.map((edge, idx) => {
          const { slug, title } = edge.node

          return (
            <div key={idx}>
              <Link to={`/tags/${slug}`}>
                #{title} ({tagCountMap[slug!]})
              </Link>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

const style = {
  heading: css`
    ${PAGE_HEADING_STYLE}
  `,
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: 1em 2em;

    a {
      ${TAG_LINK_STYLE}
    }
  `,
}

export default TagsPage

export const Head: React.FC<HeadProps> = () => {
  return <SEO title={TITLE} />
}
